import React from 'react'

const Thanks = () => {
  return (
    <div className='thanks'>
            <img  src='../check.png' alt='success image'></img>

        <h3>Your form has been submitted Successfully</h3>
        <p>Expect a feedback from us soon</p>
       
        
    </div>
  )
}

export default Thanks